import React from 'react';
import * as Yup from 'yup';
import { useFormik, Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { MdSync, MdClose } from 'react-icons/md';
import axios from 'axios';
import 'date-fns';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

const contactSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  name: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
});
const warrantySchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  name: Yup.string().required('Required'),
  dateOfPurchase: Yup.string().required('Required'),
  orderNumber: Yup.string().required('Required'),
});

const galleryImages = [
  {
    source: 'http://localhost:3000/assets/gallery/images/clocktopus-01.jpg',
    thumbnail: 'http://localhost:3000/assets/gallery/thumbs/clocktopus-01.jpg',
  },
  {
    source: 'http://localhost:3000/assets/gallery/images/clocktopus-02.jpg',
    thumbnail: 'http://localhost:3000/assets/gallery/thumbs/clocktopus-02.jpg',
  },
  {
    source: 'http://localhost:3000/assets/gallery/images/clocktopus-03.jpg',
    thumbnail: 'http://localhost:3000/assets/gallery/thumbs/clocktopus-03.jpg',
  },
  {
    source: 'http://localhost:3000/assets/gallery/images/clocktopus-04.jpg',
    thumbnail: 'http://localhost:3000/assets/gallery/thumbs/clocktopus-04.jpg',
  },
  {
    source: 'http://localhost:3000/assets/gallery/images/clocktopus-05.jpg',
    thumbnail: 'http://localhost:3000/assets/gallery/thumbs/clocktopus-05.jpg',
  },
];

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const App = (props: any) => {
  const contactFormik = useFormik({
    initialValues: {
      email: '',
      name: '',
      message: '',
    },
    validationSchema: contactSchema,
    onSubmit: async (values, { resetForm }) => {
      const { data } = await axios.post('https://api.clocktopus.net/message', {
        name: values.name,
        email: values.email,
        message: values.message,
      });
      console.log(data);
      resetForm();
    },
  });
  const warrantyFormik = useFormik({
    initialValues: {
      email: '',
      name: '',
      dateOfPurchase: '',
      orderNumber: '',
    },
    validationSchema: warrantySchema,
    onSubmit: async (values, { resetForm }) => {
      const { data } = await axios.post('https://api.clocktopus.net/warranty', {
        name: values.name,
        email: values.email,
        dateOfPurchase: values.dateOfPurchase,
        orderNumber: values.orderNumber,
      });
      console.log(data);
      resetForm();
    },
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <header id="header">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <a className="logo" href="#">
                <img src="assets/images/logo.png" alt="logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarContent"
                aria-controls="navbarContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar">
                  <i className="icofont-navigation-menu"></i>
                </span>
              </button>
              <div className="collapse navbar-collapse" id="navbarContent">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a className="nav-link active" href="#" data-scroll-nav="0">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" data-scroll-nav="1">
                      Features
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" data-scroll-nav="2">
                      Product Video
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" data-scroll-nav="3">
                      Warranty
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" data-scroll-nav="4">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <section id="home-area" data-scroll-index="0">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="caption d-table">
                <div className="d-table-cell align-middle">
                  <h1>Octopi their time with Clocktopus</h1>
                  <p>EAN: 5060863890007</p>
                  <a href="./assets/pdf/clocktopus.pdf" target="_blank">
                    Download Ebook
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="caption-img text-right">
                <img src="assets/images/clock-3.jpg" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="pattern-bg"></div>
      </section>
      <section id="warranty-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <a target="_blank" href="https://www.amazon.com/dp/B08MPBV6QW?ref=myi_title_dp">
                <img src="./assets/gallery/images/clocktopus-18.jpg" alt="" />
              </a>
              <p>EAN: 5060863890007</p>
              <a href="https://www.amazon.com/dp/B08MPBV6QW?ref=myi_title_dp" target="_blank" className="my-button">
                Buy Now
              </a>
            </div>
            <div className="col-lg-6 col-md-8">
              <a target="_blank" href="https://www.amazon.com/dp/B08XNTD58Q?ref=myi_title_dp">
                <img src="./assets/gallery/images/clocktopus-20.jpg" alt="" />
              </a>
              <p>EAN: 5060863890014</p>
              <a href="https://www.amazon.com/dp/B08XNTD58Q?ref=myi_title_dp" target="_blank" className="my-button">
                Buy Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="feature-area" data-scroll-index="1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
              <div className="section-heading text-center">
                <h2>Product Features</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="feature-single text-center">
                <div className="icon">
                  <i className="icon-star"></i>
                </div>
                <h4>High quality plastic</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="feature-single text-center">
                <div className="icon">
                  <i className="icon-star"></i>
                </div>
                <h4>Gradient colors</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="feature-single text-center">
                <div className="icon">
                  <i className="icon-star"></i>
                </div>
                <h4>Easy to read font</h4>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 col-md-6">
              <div className="feature-single text-center">
                <div className="icon">
                  <i className="icon-star"></i>
                </div>
                <h4>Words on clock hands</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="feature-single text-center">
                <div className="icon">
                  <i className="icon-star"></i>
                </div>
                <h4>Multipurpose stand</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="feature-single text-center">
                <div className="icon">
                  <i className="icon-star"></i>
                </div>
                <h4>Completion cetificate</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="video-area" data-scroll-index="2">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="video-cont">
                <h2>Best Product For You. Check The Demo Video.</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="video-box">
                <div className="d-table text-center">
                  <div className="d-table-cell align-middle">
                    <a className="popup-video mfp-iframe" href="https://youtube.com/watch?v=v3njqIESmk0">
                      <i className="icofont-ui-play"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="warranty-area" data-scroll-index="3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="section-heading">
                <h2>Register your warranty</h2>
              </div>
            </div>
          </div>
          <form onSubmit={warrantyFormik.handleSubmit}>
            <div className="row mb-4">
              <div className="col-md-12">
                <TextField
                  label="Name"
                  name="name"
                  variant="outlined"
                  color="primary"
                  value={warrantyFormik.values.name}
                  onChange={warrantyFormik.handleChange}
                  onBlur={warrantyFormik.handleBlur}
                  error={warrantyFormik.touched.name && Boolean(warrantyFormik.errors.name)}
                  helperText={warrantyFormik.touched.name && warrantyFormik.errors.name}
                  fullWidth
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <TextField
                  label="Email"
                  name="email"
                  variant="outlined"
                  color="primary"
                  value={warrantyFormik.values.email}
                  onChange={warrantyFormik.handleChange}
                  onBlur={warrantyFormik.handleBlur}
                  error={warrantyFormik.touched.email && Boolean(warrantyFormik.errors.email)}
                  helperText={warrantyFormik.touched.email && warrantyFormik.errors.email}
                  fullWidth
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <TextField
                  label="Date Of Purchase"
                  name="dateOfPurchase"
                  variant="outlined"
                  color="primary"
                  value={warrantyFormik.values.dateOfPurchase}
                  onChange={warrantyFormik.handleChange}
                  onBlur={warrantyFormik.handleBlur}
                  error={warrantyFormik.touched.dateOfPurchase && Boolean(warrantyFormik.errors.dateOfPurchase)}
                  helperText={warrantyFormik.touched.dateOfPurchase && warrantyFormik.errors.dateOfPurchase}
                  fullWidth
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <TextField
                  label="Order Number"
                  name="orderNumber"
                  variant="outlined"
                  color="primary"
                  value={warrantyFormik.values.orderNumber}
                  onChange={warrantyFormik.handleChange}
                  onBlur={warrantyFormik.handleBlur}
                  error={warrantyFormik.touched.orderNumber && Boolean(warrantyFormik.errors.orderNumber)}
                  helperText={warrantyFormik.touched.orderNumber && warrantyFormik.errors.orderNumber}
                  fullWidth
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <Button color="primary" onClick={handleClickOpen}>
                  Terms & Conditions
                </Button>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="lg">
                  <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Terms & Conditions
                  </DialogTitle>
                  <DialogContent dividers>
                    <Typography variant="button" gutterBottom>
                      Return Policy
                    </Typography>
                    <Typography gutterBottom>
                      Your purchase can be registered with us using the register your warranty form located here. By registering your
                      purchase you can know that we have your details already available should you need to contact us about your purchase.
                      To register your purchase please provide details of place of purchase, date of purchase and your order ID OR order
                      number. Along with your contact name and email address.PLEASE NOTE - ONLY PRODUCTS PURCHASED BY US DIRECTLY ARE
                      COVERED BY A WARRANTY.
                    </Typography>
                    <Typography variant="button" gutterBottom>
                      Warranty
                    </Typography>
                    <Typography gutterBottom>
                      If you have a defective product: Clocktopus will replace or provide a refund for any issues caused by manufacturer’s
                      defects. This is valid within a 30 day warranty period from date of purchase. You need to ensure that you have
                      purchased directly from Clocktopus or( Neak Products )including from any third party website through which Clocktopus
                      or Neak Products are the seller(s). Proof of purchase will be required. ***No warranty is valid for purchases made
                      through any other party***
                    </Typography>
                    <Typography variant="button" gutterBottom>
                      Further Details
                    </Typography>
                    <Typography gutterBottom>
                      After the 30 day warranty period has concluded Clocktopus is under no obligation to offer a replacement or a full
                      refund.
                    </Typography>
                    <Typography gutterBottom>
                      Clocktopus may offer replacements for products with legitimate defects after the 30 day guarantee period. This will be
                      evaluated on an individual case by case basis and replacements will be issued at Clocktopus's discretion.
                    </Typography>
                    <Typography gutterBottom>
                      A valid order ID is required for all returns this includes order number and place and date of purchase.
                    </Typography>
                    <Typography gutterBottom>
                      Both our warranty and satisfaction policy is valid for the original purchaser only.
                    </Typography>
                    <Typography gutterBottom>
                      Clocktopus’s warranty covers legitimate product defects and does not cover wear and tear*.(please see further details
                      below)
                    </Typography>
                    <Typography gutterBottom>
                      Clocktopus must be provided with a full description of the defect(s) and photographic evidence of the defect(s). The
                      order ID must also be provided.
                    </Typography>
                    <Typography gutterBottom>
                      We are very proud of our Clocktopus products. We love them and we hope you do too. Please follow these directions if
                      you have any issues or concerns with your purchase.
                    </Typography>
                    <Typography gutterBottom>
                      If you have received a damaged or defective product please notify us as soon as possible or within the warrantied 30
                      day period which commences from the date of purchase. We can be contacted on info@clocktopus.net.
                    </Typography>
                    <Typography gutterBottom>
                      Please provide the following details; Your Order ID and the item(s) you are wanting to return along with the reason
                      for the return. If the return reason is for a damaged or defective item please also provide photographic evidence of
                      the defect. We will then provide you with further instructions on how your return will be processed.
                    </Typography>
                    <Typography gutterBottom>After we have processed the return we will do one of the following</Typography>
                    <Typography gutterBottom>(1) refund the full amount paid for the item or</Typography>
                    <Typography gutterBottom>(2) ship you a replacement item</Typography>
                    <Typography variant="button" gutterBottom>
                      Return Postage Costs
                    </Typography>
                    <Typography gutterBottom>
                      The customer is responsible for the cost of shipping the returned product. Products not returned in accordance with
                      the above Return Procedures will NOT be sent back. No replacement or refund will be issued. Returns must be approved
                      before they are returned.
                    </Typography>
                    <Typography gutterBottom>
                      If your item needs to be returned for any of the following reasons a full refund of your return costs on items will be
                      provided if:
                      <ul>
                        <li>you received a damaged item, or</li>
                        <li>you received a faulty item.</li>
                      </ul>
                    </Typography>
                    <Typography gutterBottom>
                      The following defects are covered by our WARRANTY: Please note that some defects require you to notify us immediately
                      when your item is received.
                    </Typography>
                    <Typography gutterBottom>
                      manufacturer’s defects (including but is not limited to):
                      <ul>
                        <li>Broken or damaged items</li>
                        <li>Time piece dysfunctional </li>
                        <li>Missing parts. (Please notify us RIGHT AWAY)</li>
                      </ul>
                    </Typography>
                    <Typography gutterBottom>
                      ***Please take note that our return policy does not cover any damage incurred during mishandling on the part of the
                      buyer and during the return process. Please ensure that items are returned in packaging that will protect the item
                      from damage / further damage during the return process.
                    </Typography>
                    <Typography gutterBottom>
                      The following issues are NOT covered by our WARRANTY wear and tear (this includes but is not limited to):
                      <ul>
                        <li>Any damage caused by the sun, rain, or other outdoor environmental conditions.</li>
                        <li>Damage caused by accidents</li>
                        <li>Excessive wear and tear</li>
                      </ul>
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                      Accept
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={warrantyFormik.isSubmitting && <MdSync className="animate-spin-slow" />}
                  type="submit"
                  disabled={!(warrantyFormik.dirty && warrantyFormik.isValid)}
                >
                  Register
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section id="warranty-area" data-scroll-index="3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="section-heading">
                <h2>Our Educational Posters</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="d-flex">
                <a
                  target="_blank"
                  href="https://www.amazon.com/s/?keywords=educational+posters+for+kindergarten&ie=UTF8&field-asin=B08KHZ9RY1&rh=i:aps,ssx:relevance"
                >
                  <img src="./assets/images/other-products-1.png" alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-8">
              <div className="d-flex">
                <a
                  target="_blank"
                  href="https://www.amazon.com/s/?keywords=classroom+posters&ie=UTF8&field-asin=B08L37DM6W&rh=i:aps,ssx:relevance"
                >
                  <img src="./assets/images/other-products-2.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact-area" data-scroll-index="4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="section-heading">
                <h2>Contact Us</h2>
              </div>
            </div>
          </div>
          <form onSubmit={contactFormik.handleSubmit}>
            <div className="row mb-4">
              <div className="col-md-12">
                <TextField
                  label="Name"
                  name="name"
                  variant="outlined"
                  color="primary"
                  value={contactFormik.values.name}
                  onChange={contactFormik.handleChange}
                  onBlur={contactFormik.handleBlur}
                  error={contactFormik.touched.name && Boolean(contactFormik.errors.name)}
                  helperText={contactFormik.touched.name && contactFormik.errors.name}
                  fullWidth
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <TextField
                  label="Email"
                  name="email"
                  variant="outlined"
                  color="primary"
                  value={contactFormik.values.email}
                  onChange={contactFormik.handleChange}
                  onBlur={contactFormik.handleBlur}
                  error={contactFormik.touched.email && Boolean(contactFormik.errors.email)}
                  helperText={contactFormik.touched.email && contactFormik.errors.email}
                  fullWidth
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <TextField
                  label="Message"
                  name="message"
                  variant="outlined"
                  color="primary"
                  value={contactFormik.values.message}
                  onChange={contactFormik.handleChange}
                  onBlur={contactFormik.handleBlur}
                  error={contactFormik.touched.message && Boolean(contactFormik.errors.message)}
                  helperText={contactFormik.touched.message && contactFormik.errors.message}
                  fullWidth
                  multiline
                  rows={3}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={contactFormik.isSubmitting && <MdSync className="animate-spin-slow" />}
                  type="submit"
                  disabled={!(contactFormik.dirty && contactFormik.isValid)}
                >
                  Send Message
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <footer id="footer" className="bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="copyright-text text-right">
                <p>&copy; Copy 2021. All Rights Reserved By SS Retail Pty Ltd</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default App;
