import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF5229',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        outline: 'none',
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiTextField: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiTab: {
      wrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'top',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          color: '#FF5229',
        },
      },
    },
  },
});
export default theme;
